import React from "react"

import HomeScreen from "../../components/ui/HomeScreen/Lodable"

import SEO from "../../components/SEO"
import "./style.css"

export default () => {

  return (
    <>
      <SEO title="Home" />
      <main>        
        <HomeScreen />
      </main>
    </>
  )
}
