import React from "react";
import Loadable from "@loadable/component"
import { OutboundLink } from "gatsby-plugin-google-analytics";

const HomeScreen = Loadable(() =>import("."),
{
    fallback: <div className="flex bgDark relative" style={{height:"100vh"}} >
                <div className="absolute fontMontserrat w-full text-center text-white bottom-5">
                    You can connect with me, {" "}
                <OutboundLink
                    target="_blank"
                    href="https://www.linkedin.com/in/sukanta-saha-64087a55/"
                >
                    <span className="text-blue-300"> Linkedin </span>
                </OutboundLink>
                </div>
            </div>,
}
)

export default HomeScreen


